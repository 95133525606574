$(document).ready(function() {

  InterExchange.DocumentSelect = function(_, element) {
    var $element = $(element),
        $select = $element.find('select'),
        $target = $($element.data('target')),
        $otherDocument = $target.find('.other-document'),
        $instructions = $target.find('.document-instructions');

    $select.on('change', function() {
      $otherDocument.hide().find('input').prop('disabled', true);

      if ('' === this.value) {
        $instructions.hide();
      } else if ('Other' === this.value) {
        $otherDocument.show().find('input').prop('disabled', false);
        $instructions.hide();
      } else {
        const document = currentDocument(this.value);
        var instructions = '';

        if (document) {
          instructions = document.instructions;
        }

        if (instructions && instructions.length > 0) {
          // After bootstrap 3 has been removed the $instructionsCardBody declaration and the if/else
          // block can be replaced with: `$instructions.find('.card-body').html(instructions);`
          // The if/else exists to accommodate pages that still use the bootstrap 3 well and not a card.
          //
          const $instructionsCardBody = $instructions.find('.card-body');

          if ($instructionsCardBody.length == 0) {
            $instructions.html(instructions);
          }
          else {
            $instructionsCardBody.html(instructions);
          }

          $instructions.show();
        } else {
          $instructions.hide();
        }
      }
    });

    function currentDocument(documentName) {
      if ('Other' === documentName) {
        return null;
      }

      return InterExchange.DepartmentDocuments.filter(function(item) {
        return item.name == documentName;
      })[0];
    }
  };

  $('*[data-behavior="InterExchange.DocumentSelect"]').each(InterExchange.DocumentSelect);
});
